import React from 'react';
export { UserList } from './List';
export { UserDetails } from './Details';
export { UserEdit } from './Edit';
export { UserAdd } from './Add';
export { Add as CreditsHistoryAdd } from './Details/CreditsHistory/Add';

export const User: React.FC = () => {
    return null;
};
