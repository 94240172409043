import React from 'react';

export const BACKENDS_HELP_SKY = {
    header: <h2>SKy Backends</h2>,
    body: (
        <>
            <p>Some text</p>
        </>
    ),
};

export const BACKENDS_HELP_ENTERPRISE = {
    header: <h2>Enterprise Backends</h2>,
    body: (
        <>
            <p>Some text</p>
        </>
    ),
};
